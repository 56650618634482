import React from 'react';
import { GlobalStyle, colors } from '../styled';
import {
    Container,
    HeaderContainer,
    LogoContainer,
    FooterContainer,
} from './styled';
import { Link } from 'gatsby';
import { ThemeProvider } from 'styled-components';

const Header = () => (
    <HeaderContainer>
        <LogoContainer>
            <Link to="/hiking/">Emma Goto</Link>
        </LogoContainer>
    </HeaderContainer>
);

const Footer = () => (
    <FooterContainer>
        <span role="img" aria-label="">
            ⛰️
        </span>{' '}
        Find me on{' '}
        <a
            href="https://www.instagram.com/emmago.to/"
            target="_blank"
            rel="noreferrer"
        >
            Instagram
        </a>{' '}
        and{' '}
        <a
            href="
        https://yamap.com/users/2592626"
            target="_blank"
            rel="noreferrer"
        >
            Yamap
        </a>
        ! Background image from{' '}
        <a target="_blank" rel="noreferrer" href="https://jp.freepik.com/free-photo/black-and-white-mountain-background_47997154.htm#query=%E5%B1%B1%20%E3%83%91%E3%82%BF%E3%83%BC%E3%83%B3&position=29&from_view=keyword&track=ais&uuid=3a133055-872e-4d85-90f1-e6164288d254">
            Freepik
        </a>
        .
    </FooterContainer>
);

const Layout = ({ children }) => {
    return (
        <ThemeProvider theme={colors}>
            <GlobalStyle />
            <Container>
                <Header />
                <div style={{ flexGrow: 1 }}>{children}</div>
                <Footer />
            </Container>
        </ThemeProvider>
    );
};

export default Layout;
