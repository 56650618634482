import React from 'react';
import Layout from '../blog-programming/layout';
import HikingLayout from '../blog-hiking/layout';

const Layouts = ({ children, pageContext }) =>
    pageContext.collection === 'hiking' ? (
        <HikingLayout pageContext={pageContext}>
            {children}
        </HikingLayout>
    ) : (
        <Layout pageContext={pageContext}>{children}</Layout>
    );

export default Layouts;
