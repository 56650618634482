import styled from 'styled-components';

import colors from '../../common';

export const HikingBlog = styled.div`
    background-color: ${colors.black};
    border-radius: 4px;
    padding: 8px;
    a {
        color: ${colors.white};
    }
    font-size: 14px;

    &:hover {
        opacity: 0.5;
    }
`;

export const Container = styled.div`
    display: flex;
    height: 80px;
    align-items: center;
`;

export const StickyHeader = styled.div`
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 1; // To make sure sticky header sits on top of images/headers
    background-color: ${colors.darkGrey};
`;

export const LogoContainer = styled.div`
    font-size: 2em;
    padding-bottom: 8px;
    color: ${colors.purple};
    letter-spacing: 2px;
    flex-grow: 1;
    a,
    a:hover {
        color: inherit;
    }
`;

export const YellowContainer = styled.span`
    color: ${colors.yellow};
`;
