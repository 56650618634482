import styled from 'styled-components';
import {
    CONTENT_WIDTH,
    MOBILE_WIDTH,
    REGULAR_WIDTH,
} from '../styled';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: ${CONTENT_WIDTH}px;
    box-sizing: border-box;
    height: 100vh;

    margin: 0 auto;
    padding: 1.5rem 3rem;
    position: relative;

    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        padding: 1rem;
        margin: 0;
    }

    @media only screen and (min-width: ${REGULAR_WIDTH}px) {
        background-color: #1e1f21;

        background-image: url('/bg/mountains.jpg');
        background-size: 1200px;
        background-repeat: no-repeat;
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10rem;

    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        margin-bottom: 7rem;
    }

    @media only screen and (min-width: ${REGULAR_WIDTH}px) {
        margin-bottom: 10rem;
    }
`;

export const LogoContainer = styled.div`
    font-size: 1.75rem;
    letter-spacing: 1px;
    line-height: 2.25rem;
    color: #1e1f21;
    flex-grow: 1;
    a,
    a:hover {
        color: inherit;
    }
    font-weight: bold;
`;

export const FooterContainer = styled.div`
    margin-top: 2rem;
    padding: 1rem 0;
    font-size: 12px;
    color: ${(props) => props.theme.lightGrey};
    width: 100%;
`;
