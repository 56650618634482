import React from 'react';
import TerminalBar from '../../components/terminal-bar';

import {
    StickyHeader,
    Container,
    LogoContainer,
    YellowContainer,
    HikingBlog,
} from './styled';
import { Link } from 'gatsby';

const Header = () => (
    <StickyHeader>
        <Container>
            <LogoContainer>
                <Link to="/">
                    emma <YellowContainer>goto</YellowContainer>
                </Link>
            </LogoContainer>
            <Link to="/hiking/">
                <HikingBlog>
                    <span role="img" aria-label="">
                        ⛰️
                    </span>{' '}
                    Hiking blog
                </HikingBlog>
            </Link>
        </Container>
        <TerminalBar />{' '}
    </StickyHeader>
);

export default Header;
