import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import colors from './index';

export const Card = styled.div`
    padding: 16px;
    border-radius: 8px;
    color: ${colors.white};

    &:hover {
        cursor: pointer;
        opacity: 0.9;
        background-color: ${colors.darkGrey};
    }
`;

export const Interpunct = styled.div`
    padding: 0 8px;
`;

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${colors.darkGrey};
    color: ${colors.white};
    margin: 0px;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.6;
    text-rendering: optimizeLegibility;
    font-size: 1.1rem;
  }

  a {
    text-decoration: none;
    color: ${colors.blue};
  }

  a:hover {
    color: ${colors.white};
  }

  h2 a{
    color: inherit;
  }

  h2 a:hover{
    color: inherit;
  }

  h1 {
    margin-top: -4px;
    margin-bottom: 8px;
    font-weight: normal;
    letter-spacing: 1px;
  }

  h2 {
    font-weight: normal;
    margin-bottom: 8px;
    color: ${colors.green};
    letter-spacing: 1px;
    scroll-margin-top: 112px;
  }

  h3 {
    font-weight: normal;
    margin-bottom: 8px;
    letter-spacing: 1px;
    color: ${colors.darkGreen};
    scroll-margin-top: 112px;
  }

  h5 {
    margin: 0 0 8px 0;
  }

  @supports (-webkit-hyphens:none) {
    h2, h3 {
      padding-top: 112px;
      margin-top: -112px;
    }
  }

  blockquote {
    border-left: 4px solid ${colors.darkGreen};
    background-color: ${colors.darkGrey};
    padding: 8px 16px;
    border-radius: 0 8px 8px 0;

    @media screen and (max-width: 499px) {
      margin: 8px 4px;
    }
  }

  .gatsby-resp-image-image {
    border-radius: 8px;
    box-shadow: none !important;
  }

  figcaption {
    display: none;
  }

  h2, h3 {
    &:hover > .anchor.after > svg {
      visibility: visible;
      fill: ${colors.lightGrey};
      margin-left: 8px;
    }

    .anchor.after > svg {
      visibility: hidden;
    }

    .anchor {
      @media screen and (max-width: 500px) {
        display: none;
      }
    }
  }

  .language-jsx {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
  }

  .gatsby-remark-code-title {
    color: ${colors.black};
    background: -webkit-linear-gradient(top,#ebebeb,#d5d5d5);
    font-size: 12px;
    padding: 2px 14px;
    margin-top: 24px;
    margin-bottom: -30px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    font-family: "Fira Code", Consolas, Menlo, Monaco, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", "Courier New", Courier, monospace !important;
  }

  .gatsby-remark-code-title + .gatsby-highlight > pre {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin-top: 0 !important;
  }

  .gatsby-highlight-code-line {
    margin: 0 -14px;
    padding: 0 14px;
    display: block;
    background-color: rgb(51, 66, 76);
    border-left: 4px solid ${colors.darkGreen};
    padding-left: calc(14px - 4px); // to offset the border
  }

  .gatsby-resp-image-background-image {
    border-radius: 8px;
  }

  hr {
    border: none;
    height: 1px;
    background-color: #363B59;
  }
`;
