import { createGlobalStyle } from 'styled-components';
export const MOBILE_WIDTH = 500;
export const CONTENT_WIDTH = 768;
export const REGULAR_WIDTH = 1921;

export const colors = {
    black: 'black',
    darkGrey: '#1e1f21',
    white: 'white',
    lightGrey: '#c2c2c2',
    green: '#b1bf86',
    darkGreen: '#282b1d',
};

// https://www.gatsbyjs.com/plugins/gatsby-omni-font-loader/#handling-fout-with-font-loading-listener
export const GlobalStyle = createGlobalStyle`
  // Once font has loaded in
  .wf-montserrat {
    font-family: Montserrat;
    line-height: 1.6;
    text-rendering: optimizeLegibility;
    font-size: 1rem;
  }

  body {
    font-family: sans-serif;
    font-size: 17px;
    line-height: 1.5;
    visibility: visible;
    font-weight: 200;
    letter-spacing: 0.3px;


    background-color: ${colors.darkGrey};
    color: ${colors.white};
    margin: 0px;


    background-image:url('/bg/mountains.jpg');
    background-size: ${REGULAR_WIDTH}px;
    background-repeat:no-repeat;
    background-position: 50% -20px;

    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
      background-size: 1500px;
      background-position: 40% -30px;

      .anchor.after {
        display: none;
      }
    }

    @media only screen and (min-width: ${REGULAR_WIDTH}px) {
      background-image: none;
    }
  }

  a {
    text-decoration: none;
    color: ${colors.green};
  }

  a:hover {
    color: ${colors.white};
  }

  h2 a{
    color: inherit;
  }

  h2 a:hover{
    color: inherit;
  }

  h1 {
    font-weight: 500;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-top: -4px;
    margin-bottom: 8px;
  }

  h2 {
    font-weight: 500;
    margin-bottom: 8px;
    scroll-margin-top: 112px;
  }

  h3 {
    font-weight: 500;
    margin-bottom: 8px;
    scroll-margin-top: 112px;
  }

  h5 {
    margin: 0 0 8px 0;
  }

  @supports (-webkit-hyphens:none) {
    h2, h3 {
      padding-top: 112px;
      margin-top: -112px;
    }
  }

  blockquote {
    border-left: 4px solid ${colors.lightGrey};
    padding: 8px 16px;
    border-radius: 0 8px 8px 0;

    @media screen and (max-width: 499px) {
      margin: 8px 4px;
    }
  }

  figcaption {
    text-align: center;
    padding-top: 4px;
    font-size: 14px;
  }

  h2, h3 {
    &:hover > .anchor.after > * > svg {
      visibility: visible;
      fill: ${colors.lightGrey};
      margin-left: 8px;
    }

    .anchor.after > svg {
      visibility: hidden;
    }

    .anchor {
      @media screen and (max-width: 500px) {
        display: none;
      }
    }
  }

  h4 {
    margin-bottom: 8px;
  }

  .gatsby-resp-image-background-image {
    border-radius: 8px;
  }

  hr {
    border: none;
    height: 1px;
    background-color: #363B59;
  }
`;
