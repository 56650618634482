// https://github.com/voronianski/oceanic-next-color-scheme
const yellow = '#FAC863';
const green = '#99C794';
const darkGreen = '#5FB3B3';
const blue = '#6699CC';
const purple = '#C594C5';
const white = '#D8DEE9';

const lightGrey = '#A6ACC9';
const mediumGrey = '#7881AB'; // Accessible only at large fonts

// https://cocopon.github.io/iceberg.vim/
const darkGrey = '#1F2233';
const black = '#161822';

const colors = {
    yellow,
    green,
    darkGreen,
    blue,
    purple,
    white,
    lightGrey,
    mediumGrey,
    darkGrey,
    black,
};

const POST = 'post';
const REVIEW = 'review';
const BOOK_NOTE = 'book note';
const ContentType = {
    POST,
    REVIEW,
    BOOK_NOTE,
};

module.exports = colors;
module.exports.ContentType = ContentType;
